import React from 'react';
import NewLayout from "../layout/newLayout"
import styles from '../styles/scss/pages/page.module.scss'
import PageContents from '../components/pageContents'
import SEO from "../components/seo"

const Page = ({ pageContext }) => {
  const page = pageContext.page;
  return (
    <NewLayout>
      {page.seo.title && <h1 style={{ display: 'none' }}>{page.seo.title}</h1>}
      <SEO page={page} />
      <div className='d-flex flex-column flex-fill'>
        <div className={styles.containerColumn}>
          <PageContents content={page.content} />
        </div>
      </div>
    </NewLayout>
  )
}

export default Page;